<template>
  <CModal id="NotificationModal" size="lg" :show.sync="NotificationModal" @update:show="HandleClose">
    <div class="small text-right mb-3">{{ NotificationData.CreateTime }}</div>
    <div v-html="NotificationData.Detail" />
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ NotificationData.Title }}</h5>
        <CButtonClose @click="$emit('update:NotificationModal', false)" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton id="NotificationSubmit" color="success" @click="$emit('update:NotificationModal', false)">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: {
    NotificationModal: {
      type: Boolean,
      default: false
    },
    NotificationData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  name: 'Notification',
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    HandleClose (value) {
      this.$emit('update:NotificationData', {})
      this.$emit('update:NotificationModal', value)
    }
  }
}
</script>
